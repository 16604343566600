<template>
  <div class="settlement">
    <van-nav-bar title="商品结算" left-arrow fixed @click-left="$router.goBack(true)" />
    <!-- 地址 -->
    <div class="address margin" style="margin-top: 60px;" @click="selectAddr">
      <div id="address" v-if="addressInfo">
        <div class="info">
          <div class="name">{{addressInfo.consignee}}</div>
          <div class="mobile">{{addressInfo.phone}}</div>
        </div>
        <!-- <div class="addr">河北省秦皇市山海关666大厦666</div> -->
        <div
          class="addr"
        >{{addressInfo.province}}{{addressInfo.city}}{{addressInfo.area}}{{addressInfo.address}}</div>
        <div class="select-rigth">
          <i class="icon-right"></i>
        </div>
      </div>
      <div id="noaddress" @click="addAddress" v-if="!addressInfo">
        请添加地址
        <div class="select-rigth">
          <i class="icon-right"></i>
        </div>
      </div>
    </div>
    <!-- 商品信息 -->
    <div class="product-infos margin">
      <div class="product-details">
        <div class="product-imgs">
          <img :src="orderData.mainImg" alt />
        </div>
        <div class="product-right">
          <div class="product-title">{{orderData.productName}}</div>
          <div class="product-price">
            ¥ {{orderData.amt}}
            <div class="num">x1</div>
          </div>
        </div>
      </div>
      <div class="nums">
        <label class="lab">申请数量</label>
        <div class="num-c">
          <div class="remove" @click="removeNum">——</div>
          <input
            type="number"
            v-model="quantity"
            @change="quantityChange"
            :disabled="orderData.stock <= 0"
          />
          <div class="add" @click="addNum">+</div>
        </div>
      </div>
    </div>
    <!-- 运费 -->
    <div class="yf margin">
      <label class="lab">运费</label>
      <div class="value">到付</div>
    </div>
    <!-- 备注 -->
    <div class="note margin">
      <label class="lab">备注</label>
      <input type="text" placeholder="选填" v-model="remark" />
    </div>
    <!-- 合计 -->
    <div class="bottoms">
      <div class="price">
        <div>合计:¥ {{orderData.totalAmt}}</div>
        <block v-if="orderData.volume === 'Y'">
          <div >积分:{{orderData.volumeTotal}}</div>
        </block>
      </div>
      <div class="btn" @click="payShow">提交申请</div>
    </div>
    <!-- 确认申请 -->
    <ConfrmPay v-model="isPayShow" :data="orderData" />
  </div>
</template>

<script>
import ConfrmPay from "@/views/mall/component/ConfrmPay.vue";
import { Toast } from "vant";
import { settlement } from "@/api/orders";
import { detailAddr } from "@/api/address";
export default {
  name: "Settlement",
  components: {
    ConfrmPay
  },
  data() {
    return {
      productId: 0, // 商品ID
      quantity: 0, // 数量
      orderData: {}, // 结算信息
      addressInfo: undefined, // 地址信息
      remark: "", // 备注
      isPayShow: false // 是否打开提交申请窗口
    };
  },
  created() {
    const { productId, quantity } = this.$route.query;
    this.productId = productId;
    // this.quantity = quantity;
    // console.log("this.$route.params = ", this.$route.query);
    this.remark = window.$tempRemark || "";
    this.quantity = window.$tempQuantity || quantity;
    this.getData();
    // console.log("哈哈");
    if (window.$tempAddressId) {
      // 获取当前选中的地址
      console.log("当前选中的地址 =", window.$tempAddressId);
      this.getAddress(window.$tempAddressId);
    }
  },
  beforeRouteLeave(to, from, next) {
    console.log("this.remark", this.remark);
    window.$tempRemark = this.remark;
    window.$tempQuantity = this.quantity;
    next();
  },
  methods: {
    async getAddress(addrId) {
      const { data } = await detailAddr(addrId);
      this.addressInfo = data;
    },
    async getData() {
      const res = await settlement(this.productId, this.quantity);
      if (res && res.code !== 0) {
        Toast(res.msg);
        return;
      }
      this.orderData = res.data;
      // this.orderData.stock = 0;
      if (this.orderData.stock <= 0) {
        Toast("库存不足");
        this.$router.goBack(true);
        return;
      }
      // 不存在选择的地址就获取当前结算结果返回的地址
      if (!window.$tempAddressId) {
        this.addressInfo = this.orderData.address;
      }
      console.log("this.orderData =", this.orderData);
    },
    removeNum() {
      if (this.quantity <= this.orderData.moq) return;
      if (this.quantity === this.orderData.moq) return;
      this.quantity -= this.orderData.moq;
      this.getData();
    },
    addNum() {
      if (this.orderData.stock <= 0) return;
      if (this.quantity >= this.orderData.stock) return;
      if (this.quantity === 999) return;
      this.quantity += this.orderData.moq;
      this.getData();
    },
    addAddress() {
      this.$router.push({ path: "/mall/addressEdit" });
    },
    selectAddr() {
      this.$router.push({
        path: "/mall/addressList",
        query: { isSelect: true, id: this.addressInfo.id }
      });
    },
    quantityChange() {
      if (this.quantity > 9999) {
        this.quantity = 9999;
      }
      if (this.quantity <= this.orderData.moq) {
        this.quantity = this.orderData.moq;
      }
      if (this.quantity % this.orderData.moq !== 0) {
        const num = parseInt(this.quantity / this.orderData.moq);
        this.quantity = this.orderData.moq * (num !== 0 ? num : 1);
      }
      this.getData();
    },
    payShow() {
      if (!this.addressInfo) {
        Toast("请添加地址");
        return;
      }
      this.isPayShow = true;
      this.orderData.quantity = this.quantity;
      this.orderData.address = this.addressInfo;
      this.orderData.note = this.remark;
    }
  }
};
</script>

<style lang="scss">
.settlement {
  background: #f8f8f8;
  // padding: 15px 15px;
  height: 100%;
  overflow: hidden;
  .margin {
    margin: 10px 15px;
    padding: 18px 25px;
  }
  .address {
    background: #fff;
    position: relative;
    margin-top: 46px;
    .info {
      display: flex;
      .name {
        margin-right: 20px;
        margin-bottom: 20px;
      }
      .name,
      .mobile {
        font-weight: bold;
        font-size: 18px;
      }
    }
    .addr {
      color: #8f8c8c;
    }
  }
  .product-infos {
    background: #fff;
  }
  .product-imgs {
    height: 100px;
    width: 100px;
    margin-right: 20px;
    img {
      height: 100%;
      width: 100%;
    }
  }
  .product-details {
    display: flex;
    margin-bottom: 30px;
  }
  .product-right {
    flex-direction: column;
    display: flex;
  }
  .product-title {
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 60px;
  }
  .product-price {
    display: flex;
    font-weight: bold;
    color: #eb815f;
    .num {
      font-weight: normal;
      color: #000;
      margin-left: 10px;
    }
  }
  .nums {
    display: flex;
    // padding: 20px 0px;
    align-items: center;
    justify-content: space-between;
    .lab {
      font-size: 16px;
      font-weight: bold;
    }
    .num-c {
      display: flex;
      .add {
        font-size: 16px;
        font-weight: bold;
      }
      .remove {
        font-size: 16px;
        font-weight: bold;
        width: 10px;
        overflow: hidden;
      }
      input {
        display: inline-block;
        width: 50px;
        background: #f0f0f0;
        height: 20px;
        margin: 0 20px;
        border: none;
        text-align: center;
      }
    }
  }
  .yf,
  .note {
    display: flex;
    justify-content: space-between;
    background: #fff;
    font-size: 16px;
    .lab {
      font-weight: bold;
      width: 50px;
    }
    .value {
      color: #eb815f;
    }
  }
  .note {
    justify-content: start;
    align-items: center;
    input {
      width: 100%;
      display: inline-block;
      margin-left: 10px;
      border: none;
    }
  }
  .bottoms {
    position: absolute;
    width: 100%;
    height: 60px;
    left: 0;
    bottom: 0;
    display: flex;
    background: #fff;
    align-items: center;
    // padding: 0px 25px;
    overflow: hidden;
    justify-content: space-between;
    z-index: 1;
    .price {
      font-weight: bold;
      font-size: 20px;
      color: #eb815f;
      margin-left: 25px;
    }
    .btn {
      align-items: center;
      justify-content: center;
      display: flex;
      width: 130px;
      height: 100%;
      color: #fff;
      background: #eb815f;
    }
  }
  #noaddress {
    position: relative;
    padding: 15px 0;
    color: red;
  }
}
</style>
