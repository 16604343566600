import request from "@/common/request";

export function addrList(pageNum, pageSize) {
  const data = {
    pageNum: pageNum,
    pageSize: pageSize
  };
  return request({
    url: "/app/address/list",
    method: "post",
    data: JSON.stringify(data)
  });
}

export function addAddr({
  consignee,
  phone,
  province,
  city,
  area,
  areaCode,
  postalCode,
  address
}) {
  // const data = {
  //   'pageNum': pageNum,
  //   'pageSize': pageSize
  // };
  return request({
    url: "/app/address/add",
    method: "post",
    data: JSON.stringify(arguments[0])
  });
}

export function updateAddr({
  id,
  userId,
  consignee,
  phone,
  province,
  city,
  area,
  areaCode,
  postalCode,
  address
}) {
  // console.log(argum)
  // const data = {
  //   'pageNum': pageNum,
  //   'pageSize': pageSize
  // };
  return request({
    url: "/app/address/update",
    method: "post",
    data: JSON.stringify(arguments[0])
  });
}

export function deleteAddr(id) {
  return request({
    url: "/app/address/delete/" + id,
    method: "post",
    data: JSON.stringify(arguments[0])
  });
}

export function detailAddr(id) {
  return request({
    url: "/app/address/detail/" + id,
    method: "post"
  });
}
