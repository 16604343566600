import request from '@/common/request';

export function orderList(status, pageNum, pageSize) {
  const data = {
    'status': status,
    'pageNum': pageNum,
    'pageSize': pageSize
  };
  return request({
    url: '/app/order/list',
    method: 'post',
    data: JSON.stringify(data)
  });
}

export function orderDetail(orderNo) {
  return request({
    url: '/app/order/detail/' + orderNo,
    method: 'post'
  });
}

export function settlement(productId, quantity) {
  const data = {
    'productId': productId,
    'quantity': quantity
  };
  return request({
    url: '/app/order/settlement',
    method: 'post',
    data: JSON.stringify(data)
  });
}

export function placeOrder({ productId, quantity, payType, userAddressId, note }) {
  const data = {
    'productId': productId,
    'quantity': quantity,
    'payType': payType,
    'userAddressId': userAddressId,
    'note': note
  };
  return request({
    url: '/app/order/placeOrder',
    method: 'post',
    data: JSON.stringify(data)
  });
}

export function checkPayPass(payPass) {
  return request({
    url: '/app/user/checkPayPassword',
    method: 'post',
    data: JSON.stringify({
      payPassword: payPass
    })
  });
}

export function getStatusText(status) {
  if (status === 1) return "未支付";
  else if (status === 2) return "未发货";
  else if (status === 3) return "已发货";
  else if (status === 4) return "完成";
  else return "未知";
}

export function getPayTypeText(type) {
  if (type === 1) return "支付宝";
  else if (type === 2) return "微信";
  else if (type === 3) return "余额";
  else if (type === 4) return "兑换卷";
  else return "未知";
}