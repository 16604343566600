<template>
  <div class="confrm-pay" v-if="display">
    <div class="content" v-if="!isSelectPay">
      <div class="title">
        <div class="close-img-pay">
          <img class="closeOn" src="../../../assets/close.png" @click="onClose" />
        </div>确认申请
      </div>
      <div class="ok-price">¥ {{data.totalAmt}}</div>
      <div class="items">
        <div class="order-info">
          <label for class="text">订单信息</label>
          <div class="value">{{data.productName}}</div>
        </div>
        <div class="pay-type">
          <label for class="text">提交方式</label>
          <div class="value" @click="isSelectPay = true">
            <div class="pay">{{payTypes[selectPayTypeIndex].text}}</div>
            <div class="select-rigth">
              <i class="icon-right"></i>
            </div>
          </div>
        </div>
      </div>
      <div class="pay-ok">
        <div class="btn" @click="onOk">提交申请</div>
      </div>
    </div>
    <div class="content" v-if="isSelectPay">
      <div class="title">
        <div class="left-img-pay">
          <img class="closeOn" src="../../../assets/left.png" @click="onReturn" />
        </div>选择申请方式
      </div>
      <div class="select-pay-types">
        <div class="pay-type-item" @click="onSelectPayType(1)">
          <div class="left-info">
            <img src="../../../assets/alipay.png" alt />
            <div class="left-title">支付宝</div>
          </div>
          <div class="rigit-info">
            <img src="../../../assets/right.png" alt />
          </div>
        </div>
        <div class="pay-type-item" @click="onSelectPayType(2)">
          <div class="left-info">
            <img src="../../../assets/wx.png" alt />
            <div class="left-title">微信</div>
          </div>
          <div class="rigit-info">
            <img src="../../../assets/right.png" alt />
          </div>
        </div>
        <!-- <div class="pay-type-item" @click="onSelectPayType(3)" v-if="data.walletPayOk === 'Y'">
          <div class="left-info">
            <img src="../../../assets/wall.png" alt />
            <div class="left-title">余额</div>
          </div>
          <div class="rigit-info">
            <div class="rigit-num">¥ {{data.walletAmt}}</div>
            <img src="../../../assets/right.png" alt />
          </div>
        </div>-->
        <div
          class="pay-type-item"
          @click="onSelectPayType(4)"
          v-if="data.userVolumeCount >= data.volumeTotal && data.volume === 'Y'"
        >
          <div class="left-info">
            <img src="../../../assets/jifen.png" alt />
            <div class="left-title">积分</div>
          </div>
          <div class="rigit-info">
            <div class="rigit-num">{{data.userVolumeCount}}/{{data.volumeTotal}}</div>
            <img src="../../../assets/right.png" alt />
          </div>
        </div>
        <div class="disabled-pay-type">以下支付方式不可用</div>
        <div class="pay-type-disabled">
          <!-- <div class="pay-type-item2" @click="onSelectPayType(3)" v-if="data.walletPayOk === 'N'">
            <div class="left-info">
              <img src="../../../assets/wall.png" alt />
              <div class="left-title">余额</div>
            </div>
            <div class="rigit-info">
              <div class="rigit-num">¥ {{data.walletAmt}}</div>
              <img src="../../../assets/right.png" alt />
            </div>
          </div>-->
          <div
            class="pay-type-item2"
            v-if="data.userVolumeCount < data.volumeTotal || data.volume === 'N'"
          >
            <div class="left-info">
              <img src="../../../assets/jifen.png" alt />
              <div class="left-title">积分</div>
            </div>
            <div class="rigit-info">
              <div class="rigit-num">{{data.userVolumeCount}}/{{data.volumeTotal}}</div>
              <img src="../../../assets/right.png" alt />
            </div>
          </div>
        </div>
        <div class="blank"></div>
      </div>
    </div>
    <van-popup
      v-model="showPayPass"
      position="bottom"
      closeable
      close-icon-position="top-left"
      :style="{ height: '72%' }"
    >
      <div class="pass-title">输入支付密码</div>
      <div class="pass-content">
        <van-password-input
          :value="payPass"
          :error-info="errorInfo"
          info="密码为 6 位数字"
          :focused="showKeyboard"
          @focus="showKeyboard = true"
        />
        <!-- 数字键盘 -->
        <van-number-keyboard
          :show="showKeyboard"
          @input="onInput"
          @delete="onDelete"
          @blur="showKeyboard = false"
        />
        <van-loading v-if="payLoadding" style="margin-top:30px;" size="24" vertical />
      </div>
    </van-popup>
  </div>
</template>

<script>
import { placeOrder, checkPayPass } from "@/api/orders";
import { Toast } from "vant";
export default {
  name: "ConfrmPay",
  props: {
    data: Object,
    value: Boolean
  },
  data() {
    return {
      title: "确认申请",
      title2: "选择申请方式",
      isSelectPay: false, // 是否选择提交方式
      display: this.value,
      selectPayTypeIndex: 1,
      showPayPass: false, // 显示输入支付密码界面
      payPass: "", // 支付密码
      errorInfo: "", // 支付密码错误提示
      showKeyboard: false, // 是否显示支付密码数字键盘
      payLoadding: false, // 支付密码加载中
      payTypes: [
        { id: 1, text: "支付宝" },
        { id: 2, text: "微信" },
        { id: 3, text: "余额" },
        { id: 4, text: "积分兑换" }
      ],
      payFromId: "payId"
    };
  },
  created() {
    const payFrom = document.getElementById(this.payFromId);
    if (payFrom) {
      document.body.removeChild(payFrom);
    }
  },
  watch: {
    value(newV, oldV) {
      // do something
      this.display = newV;
    },
    data(newV, oldV) {
      // console.log("newV = ", newV);
      // console.log("oldV = ", oldV);
    }
  },
  methods: {
    onClose() {
      this.display = false;
      this.$emit("input", this.display);
    },
    onReturn() {
      this.isSelectPay = false;
    },
    selectPay() {},
    onSelectPayType(id) {
      for (const index in this.payTypes) {
        if (id === this.payTypes[index].id) {
          this.selectPayTypeIndex = index;
        }
      }
      this.isSelectPay = false;
    },
    async placeOrder() {
      const payType = this.payTypes[this.selectPayTypeIndex].id;
      const { code, data, msg } = await placeOrder({
        productId: this.data.productId,
        quantity: this.data.quantity,
        payType: payType,
        userAddressId: this.data.address.id,
        note: this.data.note
      });
      if (code !== 0) {
        Toast(msg);
        return;
      }
      if (payType === 1) {
        // 支付宝支付
        const div = document.createElement("div");
        div.setAttribute("id", this.payFromId);
        console.log("div = ", div);
        /* 此处form就是后台返回接收到的数据 */
        div.innerHTML = data;
        document.body.appendChild(div);
        document.forms[0].submit();
        const payFrom = document.getElementById(this.payFromId);
        document.body.removeChild(payFrom);
      } else if (payType === 2) {
        // document.body.innerHTML = data;
        console.log("data =" + data);
        window.location.href = data;
      } else if (payType === 3) {
        window.location.href = data;
      } else {
        window.location.href = data;
      }
    },
    onOk() {
      const payType = this.payTypes[this.selectPayTypeIndex].id;
      if (payType === 4) {
        this.showPayPass = true;
        this.showKeyboard = true;
      } else {
        this.placeOrder();
      }
    },
    onInput(key) {
      // 加载中不能输入
      if (this.payLoadding) return;
      this.payPass = (this.payPass + key).slice(0, 6);
      if (this.payPass.length === 6) {
        this.payLoadding = true;
        checkPayPass(this.payPass)
          .then(res => {
            this.payLoadding = false;
            if (res.code !== 0) {
              this.errorInfo = res.msg;
              this.payPass = "";
              return;
            }
            this.placeOrder();
          })
          .catch(() => {
            this.payLoadding = false;
            this.errorInfo = "服务器连接超时";
          });
      } else {
        this.errorInfo = "";
      }
    },
    onDelete() {
      // 加载中不能删除
      if (this.payLoadding) return;
      this.payPass = this.payPass.slice(0, this.value.length - 1);
    }
  }
};
</script>
<style lang="scss">
.confrm-pay {
  position: fixed;
  z-index: 30;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(155, 155, 155, 0.3);
  .content {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    // height: ;
    background: #fff;
  }
  .title {
    height: 54px;
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    border-bottom: 1px #e0dcdc solid;
  }
  .close-img-pay {
    position: absolute;
    height: 100%;
    left: 20px;
    top: 0;
    display: flex;
    align-items: center;
  }
  .left-img-pay {
    position: absolute;
    height: 100%;
    left: 20px;
    top: 0;
    display: flex;
    align-items: center;
    img {
      width: 15px;
      height: 15px;
    }
  }
  .ok-price {
    padding: 30px 0;
    display: flex;
    justify-content: center;
    font-size: 23px;
    font-weight: bold;
  }
  .items {
    padding: 0px 15px;
  }
  .order-info {
    border-bottom: 1px #e0dcdc solid;
  }
  .order-info,
  .pay-type {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    .value {
      position: relative;
    }
    .pay {
      margin-right: 30px;
    }
    .select-rigth {
      right: 0;
    }
  }
  .pay-ok {
    width: 100%;
    padding: 100px 0px;
    display: flex;
    justify-content: center;
    .btn {
      width: 95%;
      align-items: center;
      justify-content: center;
      display: flex;
      height: 50px;
      background: #eb815f;
      color: #fff;
      border-radius: 10px;
    }
  }
  .select-pay-types {
    margin-top: 20px;
    .pay-type-item {
      display: flex;
      justify-content: space-between;
      margin-left: 15px;
      padding: 10px 0;
    }
    .pay-type-item2 {
      display: flex;
      justify-content: space-between;
      margin-left: 15px;
      padding: 10px 0;
    }
    .left-info {
      display: flex;
      align-items: center;
      // margin-left: 15px;
      .left-title {
        margin-left: 15px;
      }
    }
    .rigit-info {
      display: flex;
      align-items: center;
      .rigit-num {
        // display: flex;
        // align-items: center;
        // height: 100%;
        color: #696666;
        font-size: 14px;
        margin-right: 15px;
      }
      img {
        height: 15px;
        width: 15px;
        margin-right: 15px;
      }
    }
    .disabled-pay-type {
      font-size: 13px;
      text-align: center;
      color: #c2c1c1;
      padding: 5px 0;
    }
    .pay-type-disabled {
      background: #e5e5e5;
    }
    .pay-type-item:nth-child(n + 2) {
      border-top: 1px solid #e0dcdc;
    }
    .pay-type-item2:nth-child(2) {
      border-top: 1px solid rgb(151, 150, 150);
    }
  }
  .blank {
    height: 160px;
  }
  .pass-title {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
  }
  .pass-content {
    margin-top: 50px;
  }
}
</style>
